<template>
  <div>
    <CBreadcrumb :items="links" style="margin-top: -28px;"/>
    
    <CRow>
			<CCol sm="9">
				<CCol sm="12">
				<CCard>
					<CCardHeader>
						<strong>PIHAK PERTAMA telah menyerahkan kepada PIHAK KEDUA</strong> barang-barang seperti tersebut di bawah ini :
					</CCardHeader>
					<CCardBody>
            <CDataTable
							hover
							striped
							border
							small
							sorter
							pagination
							:items="items"
							:fields="fields"
							:items-per-page="perPage"
							:loading="isLoading"
						>
							<template #id="{item}">
					      <td align="center">{{items.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
				      </template>
							<template #isDualFeed="{item}">
            		<td align="center">
              		{{item.isDualFeed === 0 ? 'Tidak' : 'Ya'}}
            		</td>
          		</template>
							<template #rack="{item}">
            		<td align="left">
              		{{item.rack === null ? '' : item.rack}}
            		</td>
          		</template>
							<template #total="{item}">
            		<td align="right">
              		{{item.total}}
            		</td>
          		</template>
							<template #note="{item}">
            		<td align="left">
              		{{item.note === null ? '' : item.note}}
            		</td>
          		</template>
							<template #action="{item}">
								<td align="center">
									<CButton
            				color="primary"
            				variant="outline"
            				square
            				size="sm"
            				@click="openModal(item.id)"
          				>
            				edit
          				</CButton>&nbsp;&nbsp;
									<CButton
            				color="danger"
            				variant="outline"
            				square
            				size="sm"
            				@click="delItem(item.id)"
          				>
            				remove
          				</CButton>
								</td>
							</template>
						</CDataTable>
            <p style="margin-top: 12px; margin-bottom: 16px;">Demikian Berita Acara Serah Terima ini dibuat dan ditanda tangani untuk dapat dipergunakan sebagaimana mestinya.</p>
            <CRow>
              <CCol sm="6" class="text-center">
                <h6 style="margin-bottom: 12px;">PIHAK PERTAMA,</h6>
                <!-- <CButton v-if="approval.first_party_sign === null" size="sm" color="success" @click="openModal('first_party')">Tandatangani</CButton> -->
								<VueSignaturePad
									v-if="approval.first_party_sign === null"
              		id="signature"
              		width="100%"
              		height="200px"
              		ref="signaturePad1"
              		:options="options"
            		/>
								<CInput
									v-if="approval.first_party_sign === null"
									type="text"
									v-model="approval.first_party"
									placeholder="masukkan nama lengkap"
									required
								/>
								<div class="buttons" v-if="approval.first_party_sign === null">
            			<CButton size="sm" color="warning" @click="undo(1)">Undo</CButton>
            			<CButton size="sm" color="danger" @click="clear(1)">Clear</CButton>
									<CButton size="sm" color="success" @click="save(1, 'first_party')">Save</CButton>
          			</div>
                <img
                  v-if="approval.first_party_sign !== null"
                  style="margin-top: -18px; height: 95px; width: 100%;"
                  :src="'https://dcvisit.lintasarta.net/api/public/' + approval.first_party_sign"
                >
								<p v-if="approval.first_party_sign !== null">{{approval.first_party}}</p>
              </CCol>
              <CCol sm="6" class="text-center">
                <h6 style="margin-bottom: 12px;">PIHAK KEDUA,</h6>
                <!-- <CButton v-if="!approval.second_party_sign === null" size="sm" color="success" @click="openModal('second_party')">Tandatangani</CButton> -->
								<VueSignaturePad
									v-if="approval.second_party_sign === null"
              		id="signature"
              		width="100%"
              		height="200px"
              		ref="signaturePad2"
              		:options="options"
            		/>
								<CInput
									v-if="approval.second_party_sign === null"
									type="text"
									v-model="approval.second_party"
									placeholder="masukkan nama lengkap"
									required
								/>
								<div class="buttons" v-if="approval.second_party_sign === null">
            			<CButton size="sm" color="warning" @click="undo(2)">Undo</CButton>
            			<CButton size="sm" color="danger" @click="clear(2)">Clear</CButton>
									<CButton size="sm" color="success" @click="save(2, 'second_party')">Save</CButton>
          			</div>
                <img
                  v-if="approval.second_party_sign !== null"
                  style="margin-top: -18px; height: 95px; width: 100%;"
                  :src="'https://dcvisit.lintasarta.net/api/public/' + approval.second_party_sign"
                >
								<p v-if="approval.second_party_sign !== null">{{approval.second_party}}</p>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="12">
				<CCard>
					<CCardHeader>
						<strong>PERSETUJUAN MASUK/KELUAR BARANG DATA CENTER</strong>
					</CCardHeader>
					<CCardBody>
            <CRow>
              <CCol sm="12">
                <CTextarea
                  label="Alasan Persetujuan"
									v-model="approval.approval_note"
                  rows="4"
                />
              </CCol>
              <CCol sm="6" class="text-center">
                <h6 style="margin-bottom: 12px;">Petugas Data Center,</h6>
								<!-- <CButton v-if="!approval.officer_approval_sign === null" size="sm" color="success" @click="openModal('officer_approval')">Tandatangani</CButton> -->
								<VueSignaturePad
									v-if="approval.officer_approval_sign === null"
              		id="signature"
              		width="100%"
              		height="200px"
              		ref="signaturePad3"
              		:options="options"
            		/>
								<CInput
									v-if="approval.officer_approval_sign === null"
									type="text"
									class="text-center"
									v-model="approval.officer_approval"
									placeholder="masukkan nama lengkap"
									required
								/>
								<div class="buttons" v-if="approval.officer_approval_sign === null">
            			<CButton size="sm" color="warning" @click="undo(3)">Undo</CButton>
            			<CButton size="sm" color="danger" @click="clear(3)">Clear</CButton>
									<CButton size="sm" color="success" @click="save(3, 'officer_approval')">Save</CButton>
          			</div>
                <img
                  v-if="approval.officer_approval_sign !== null"
                  style="margin-top: -18px; height: 95px; width: 100%;"
                  :src="'https://dcvisit.lintasarta.net/api/public/' + approval.officer_approval_sign"
                >
								<p v-if="approval.officer_approval_sign !== null">{{approval.officer_approval}}</p>
              </CCol>
              <CCol sm="6" class="text-center">
                <h6 style="margin-bottom: 12px;">Security Lintasarta,</h6>
								<!-- <CButton v-if="!approval.security_approval_sign === null" size="sm" color="success" @click="openModal('security_approval')">Tandatangani</CButton> -->
								<VueSignaturePad
									v-if="approval.security_approval_sign === null"
              		id="signature"
              		width="100%"
              		height="200px"
              		ref="signaturePad4"
              		:options="options"
            		/>
								<CInput
									v-if="approval.security_approval_sign === null"
									type="text"
									v-model="approval.security_approval"
									placeholder="masukkan nama lengkap"
									required
								/>
								<div class="buttons" v-if="approval.security_approval_sign === null">
            			<CButton size="sm" color="warning" @click="undo(4)">Undo</CButton>
            			<CButton size="sm" color="danger" @click="clear(4)">Clear</CButton>
									<CButton size="sm" color="success" @click="save(4, 'security_approval')">Save</CButton>
          			</div>
                <img
                  v-if="approval.security_approval_sign !== null"
                  style="margin-top: -18px; height: 95px; width: 100%;"
                  :src="'https://dcvisit.lintasarta.net/api/public/' + approval.security_approval_sign"
                >
								<p v-if="approval.security_approval_sign !== null">{{approval.security_approval}}</p>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
			<!-- <CRow class="mb-3 text-right">
			<CCol sm="9">
			</CCol>
			<CCol sm="3">
				<CButton color="primary" block :disabled="approval.first_party === '' && approval.second_party === ''" @click="approve">Approve</CButton>
			</CCol>
		</CRow> -->
			</CCol>
			<CCol sm="3">
				<CCard>
					<CCardBody>
						<!-- for further information -->
						
					</CCardBody>
				</CCard>
			</CCol>
			
    </CRow>

		<!-- <CModal
      color="dark"
      :show.sync="signModal"
			:no-close-on-backdrop="true"
    	:centered="true"
    >
      <CRow>
				<CCol sm="12">
          <div class="container">
						<CInput
							type="text"
							description=""
							:label="'Nama ' + adapter(signDest)"
							placeholder="Masukkan nama lengkap sesuai ID"
							:value.sync="name"
							required
						/>
            <p class="sign">Tanda Tangan</p>
            <VueSignaturePad
              id="signature"
              width="100%"
              height="200px"
              ref="signaturePad"
              :options="options"
            />
          </div>
          <div class="buttons">
            <CButton size="sm" color="warning" @click="undo">Undo</CButton>
            <CButton size="sm" color="danger" @click="clear">Clear</CButton>
          </div>
        </CCol>
			</CRow>
			<template #header>
      	<h6 class="modal-title">Serah Terima Barang</h6>
      	<CButtonClose @click="signModal = false" class="text-white"/>
    	</template>
    	<template #footer class="text-right">
      	<CButton @click="signModal = false" color="">Batal</CButton>
      	<CButton @click="save(signDest)" color="">Simpan</CButton>
    	</template>
    </CModal> -->
  </div>
</template>

<script>
import http from '@/utils/http-common';

export default {
  name: 'PageVisitBast',
  data () {
		return {
			signModal: false,
			options: {
        dotSize: '0.1',
        penColor: "#333",
      },
			/* options: {
        dotSize: '0.1',
        penColor: "#333",
				onBegin: () => {$refs.signaturePad.resizeCanvas()}
      }, */
      visitId: this.$route.params.visit_id,
      id: this.$route.params.id,
      items: [],
      bastStatus: 0,
      bast: [],
			signDest: 'first_party',
			name: '',
			approval: {
				handover_id: this.id,
				approval_note: '',
				first_party: '',
				first_party_sign: '',
				second_party: '',
				second_party_sign: '',
				officer_approval: '',
				officer_approval_sign: '',
				security_approval: '',
				security_approval_sign: '',
			},
      links: [],
      currentPage: 1,
			perPage: 5,
			totalRows: 1,
			isLoading: true,
      fields: [
        { key: 'id', label: 'No' },
				{ key: 'item_name', label: 'Nama Barang' }, 
				{ key: 'serial', label: 'Serial Number' }, 
				{ key: 'total', label: 'Jumlah' }, 
				'rack', 
				{ key: 'isDualFeed', label: 'Dual Feed' }, 
				{ key: 'note', label: 'Keterangan' }, 
			],
		}
	},
  methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 4000,
        dismissible: true
      })
    },
		adapter(field) {
			switch(field) {
  			case 'first_party':
    			// code
					this.signDest = 'first_party';
					return 'Pihak Pertama';
    			break;
  			case 'second_party':
    			// code
					this.signDest = 'second_party';
					return 'Pihak Kedua';
    			break;
				case 'officer_approval':
    			// code
					this.signDest = 'officer_approval';
					return 'Petugas Data Center';
    			break;
				case 'security_approval':
    			// code
					this.signDest = 'security_approval';
					return 'Security Lintasarta';
    			break;
			}
		},
    getBast(id) {
			let self = this;
			return http.get('/handover/' + id)
			.then(function (response) {
				// console.log(response.data.data)
				self.items = response.data.data.detail;
				self.bastStatus = response.data.data.status;
				self.approval = response.data.data;
        self.isLoading= false;
			}).catch(function (error) {
				console.log(error);
        self.isLoading= false;
			});
		},
		openModal(dest) {
			this.signModal = true;
			this.adapter(dest);
		},
		undo(id) {
			switch(id) {
  			case 1:
    			// code
					this.$refs.signaturePad1.undoSignature();
    			break;
  			case 2:
    			// code
					this.$refs.signaturePad2.undoSignature();
    			break;
				case 3:
    			// code
					this.$refs.signaturePad3.undoSignature();
    			break;
				case 4:
    			// code
					this.$refs.signaturePad4.undoSignature();
    			break;
			}
    },
    clear(id) {
			switch(id) {
  			case 1:
    			// code
					this.$refs.signaturePad1.clearSignature();
    			break;
  			case 2:
    			// code
					this.$refs.signaturePad2.clearSignature();
    			break;
				case 3:
    			// code
					this.$refs.signaturePad3.clearSignature();
    			break;
				case 4:
    			// code
					this.$refs.signaturePad4.clearSignature();
    			break;
			}
      this.toast('Tandatangan dihapus.')
    },
    save(id, signDest) {
      let self = this;
			let data;

			switch(id) {
  			case 1:
    			// code
					data = this.$refs.signaturePad1.saveSignature();
    			break;
  			case 2:
    			// code
					data = this.$refs.signaturePad2.saveSignature();
    			break;
				case 3:
    			// code
					data = this.$refs.signaturePad3.saveSignature();
    			break;
				case 4:
    			// code
					data = this.$refs.signaturePad4.saveSignature();
    			break;
			}

			// console.log(data)

			let formData = new FormData();
			formData.append('handover_id', this.id);

			switch(signDest) {
  			case 'first_party':
    			// code
					self.approval.first_party_sign = data.data;

					formData.append('first_party', self.approval.first_party);
					formData.append('first_party_sign', self.approval.first_party_sign);
					http.post('/handover/signatureFirstParty', formData, {
      			headers: {
        			'Content-Type': 'multipart/form-data'
      			}
    			})
					.then(function (response) {
        		// console.log(response);
						self.toast('Tandatangan Pihak Pertama Tersimpan.', 'info')
						self.getBast(self.id);
					}).catch(function (error) {
						console.log(error);
						self.toast(error, 'danger')
					});

					self.$refs.signaturePad1.clearSignature();
    			break;
  			case 'second_party':
    			// code
					self.approval.second_party_sign = data.data;

					formData.append('second_party', self.approval.second_party);
					formData.append('second_party_sign', self.approval.second_party_sign);
					http.post('/handover/signatureSecondParty', formData, {
      			headers: {
        			'Content-Type': 'multipart/form-data'
      			}
    			})
					.then(function (response) {
        		// console.log(response);
						self.toast('Tandatangan Pihak Kedua Tersimpan.', 'info')
						self.getBast(self.id);
					}).catch(function (error) {
						console.log(error);
						self.toast(error, 'danger')
					});

					self.$refs.signaturePad2.clearSignature();
    			break;
				case 'officer_approval':
    			// code
					self.approval.officer_approval_sign = data.data;

					formData.append('officer_approval', self.approval.officer_approval);
					formData.append('approval_note', this.approval.approval_note);
					formData.append('officer_approval_sign', self.approval.officer_approval_sign);
					http.post('/handover/signatureOfficer', formData, {
      			headers: {
        			'Content-Type': 'multipart/form-data'
      			}
    			})
					.then(function (response) {
        		// console.log(response);
						self.toast('Tandatangan Officer Lintasarta Tersimpan.', 'info')
						self.getBast(self.id);
					}).catch(function (error) {
						console.log(error);
						self.toast(error, 'danger')
					});

					self.$refs.signaturePad3.clearSignature();
    			break;
				case 'security_approval':
    			// code
					self.approval.security_approval_sign = data.data;

					formData.append('security_approval', self.approval.security_approval);
					formData.append('security_approval_sign', self.approval.security_approval_sign);
					http.post('/handover/signatureSecurity', formData, {
      			headers: {
        			'Content-Type': 'multipart/form-data'
      			}
    			})
					.then(function (response) {
        		// console.log(response);
						self.toast('Tandatangan Security Lintasarta Tersimpan.', 'info')
						self.getBast(self.id);
					}).catch(function (error) {
						console.log(error);
						self.toast(error, 'danger')
					});

					self.$refs.signaturePad4.clearSignature();
    			break;
			}
    },
		approve() {
			let self = this;
			let formData = new FormData();

			formData.append('handover_id', this.id);
			formData.append('approval_note', this.approval.approval_note);
			/* formData.append('first_party', this.approval.first_party);
			formData.append('second_party', this.approval.second_party);
			formData.append('officer_approval', this.approval.officer_approval);
			formData.append('security_approval', this.approval.security_approval);
			formData.append('first_party_signature', this.approval.first_party_sign);
			formData.append('second_party_signature', this.approval.second_party_sign);
			formData.append('officer_approval_signature', this.approval.officer_approval_sign);
			formData.append('security_approval_signature', this.approval.security_approval_sign); */

			return http.post('/handover/sign', formData, {
      	headers: {
        	'Content-Type': 'multipart/form-data'
      	}
    	})
			.then(function (response) {
        // console.log(response);
				self.toast('Berita Acara Serah Terima Tersimpan.', 'info')
			}).catch(function (error) {
				console.log(error);
				self.toast(error, 'danger')
			});
		}
  },
  mounted: function(){
    this.links = [
      {
        text: 'Home',
        href: '#/'
      }, 
      {
        text: 'Daftar Pengajuan Kunjungan',
        href: '#/approval/visit-request'
      }, 
      {
        text: 'Detail Pengajuan Kunjungan',
        href: '#/approval/visit-request/' + this.visitId
      },
      {
        text: 'Approval BAST'
      }
    ],
    this.getBast(this.id);
		/* if (this.approval.first_party_sign === '') {
			this.signModal = true;
		} */
  },
}

</script>
<style scoped>
#signature {
  border: solid 1px #ececee;
  border-radius: 5px;
}

.container {
  width: 100%;
  padding: 6px;
}

.sign {
  text-align: left;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 6px;
	margin-bottom: 6px;
}
</style>
